import { landingRoute, i18n, /* slashCheck, */ asyncComponent, regexpRoute } from 'helpers/router';

import loadable from '@loadable/component';
// import landingRoutes from './landing-routes';

export default function ({ amp } = {}) { // eslint-disable-line
  // let prefix = '/';
  // if (amp) prefix = '/amp';

  return i18n({
    path: '/',
    component: loadable(() => import(/* webpackChunkName: "body" */ './body')),
    indexRoute: {
      component: loadable(() => import(/* webpackChunkName: "home" */ './page-home')),
      noLowerMenu: true,
      mods: 'pageFill'
    },
    childRoutes: [
      // Subscription unsubscribe page
      {
        path: 'unsubscribe',
        component: loadable(() => import(/* webpackChunkName: "routes-unsubscribe" */ 'subsys/notifications/page-unsubscribe'))
      },
      {
        path: 'auth/messages.html',
        component: loadable(() => import(/* webpackChunkName: "messages" */ 'src-sites/domik/section-profile/page-messages'))
      },
      {
        path: 'clubs',
        getComponent: asyncComponent(() =>
          import('./clubs/home'))
      },
      {
        path: 'contest',
        getComponent: asyncComponent(() =>
          import('./contest/home'))
      },
      {
        path: 'tags/:tag',
        getComponent: asyncComponent(() =>
          import('./forums/tags'))
      },
      {
        path: 'tags/:tag/page-:page',
        getComponent: asyncComponent(() =>
          import('./forums/tags'))
      },
      {
        path: 'search',
        component: loadable(() => import('./search/search-page'))
      },
      /*
      @deprecated
      {
        path: 'search/:search/page-:page',
        getComponent: asyncComponent(() =>
          import('./search/search-page'))
      },
      {
        path: 'search/:search',
        getComponent: asyncComponent(() =>
          import('./search/search-page'))
      },
      */
      // ------------ CLUBS ---------------------
      {
        path: 'clubs/:url*',
        getComponent: regexpRoute([
          {
            match: /^page-(\d+)$/,
            component: () => import('./clubs/home'),
            params: ['page']
          },
          {
            match: /^(\S+)-c(\d+)-(\S+)\/page-(\d+)$/,
            component: () => import('./clubs/club/club'),
            params: ['title', 'id', 'status', 'page']
          },
          {
            match: /^(\S+)-c(\d+)-(\S+)$/,
            component: () => import('./clubs/club/club'),
            params: ['title', 'id', 'status']
          },
          {
            match: /^(\S+)-c(\d+)\/page-(\d+)$/,
            component: () => import('./clubs/club/club'),
            params: ['title', 'id', 'page']
          },
          {
            match: /^(\S+)-c(\d+)$/,
            component: () => import('./clubs/club/club'),
            params: ['title', 'id']
          },
          /*          {
            match: /^(\S+)-t(\d+)\/page-(\d+)$/,
            component: () => import('./clubs/club/club-topic'),
            params: ['title', 'id', 'page']
          },
          {
            match: /^(\S+)-t(\d+)$/,
            component: () => import('./clubs/club/club-topic'),
            params: ['title', 'id']
          }, */
        ])
      },
      // ------------ CONTEST -------------------
      {
        path: 'contest/:url*',
        getComponent: regexpRoute([
          {
            match: /^page-(\d+)$/,
            component: () => import('./contest/home'),
            params: ['page']
          },
        ])
      },
      // ------------ FORUMS --------------------
      {
        path: 'unread-messages',
        getComponent: asyncComponent(() =>
          import('./forums/unread-messages'))
      },
      {
        path: 'filtered-messages',
        getComponent: asyncComponent(() =>
          import('./forums/filtered-messages'))
      },
      {
        path: 'user-posts-u:user_id*',
        component: loadable(() => import('./common/user/user-posts'))
      },
      {
        path: 'member:user_id.html*',
        component: loadable(() => import('./common/user/user-page'))
      },
      // ------------ Favorites -----------------
      {
        path: 'auth/favorites/:project',
        getComponent: asyncComponent(() =>
          import(/* webpackChunkName: "favorites" */ './subsys/favorites')),
        mods: 'pageFill'
      },
      {
        path: '*',
        getComponent: landingRoute({
          forum: {
            component: loadable(() => import('./forums/page-forum')),
            pages: false // Custom implementation of pages used
          },
          topic: {
            component: loadable(() => import('./clubs/club/club-topic')),
            pages: false // Custom implementation of pages used
          },
          topicRegistry: {
            component: loadable(() => import('./common/investor-content')),
            pages: false // Custom implementation of pages used
          },
        })
      },

      /*
      {
        path: ':url*',
        getComponent: regexpRoute([
          {
            match: /^(\S+)-f(\d+)\/page-(\d+)$/,
            component: () => import('./forums/page-forum'),
            params: ['title', 'id', 'page']
          },
          {
            match: /^(\S+)-f(\d+)$/,
            component: () => import('./forums/page-forum'),
            params: ['title', 'id']
          },
          {
            match: /^(\S+)-t(\d+)\/search\/(.+)\/page-(\d+)$/,
            component: () => import('./clubs/club/club-topic'),
            params: ['title', 'id', 'search', 'page']
          },
          {
            match: /^(\S+)-t(\d+)\/search\/(.+)$/,
            component: () => import('./clubs/club/club-topic'),
            params: ['title', 'id', 'search']
          },
          {
            match: /^(\S+)-t(\d+)\/page-(\d+)$/,
            component: () => import('./clubs/club/club-topic'),
            params: ['title', 'id', 'page']
          },
          {
            match: /^(\S+)-t(\d+)$/,
            component: () => import('./clubs/club/club-topic'),
            params: ['title', 'id']
          },
          {
            match: /^(\S+)-t(\d+)\/reestr-investorov$/,
            component: () => import('./common/investor-content'),
            params: ['title', 'id']
          },
          {
            match: /^user-topics-u(\d+)\/page-(\d+)$/,
            component: () => import('./common/user/user-topics'),
            params: ['id', 'page']
          },
          {
            match: /^user-topics-u(\d+)$/,
            component: () => import('./common/user/user-topics'),
            params: ['id']
          },
          {
            match: /^user-clubs-u(\d+)\/page-(\d+)$/,
            component: () => import('./common/user/user-clubs'),
            params: ['id', 'page']
          },
          {
            match: /^user-clubs-u(\d+)$/,
            component: () => import('./common/user/user-clubs'),
            params: ['id']
          },
          {
            match: /^user-votes-u(\d+)\/page-(\d+)$/,
            component: () => import('./common/user/user-votes'),
            params: ['id', 'page']
          },
          {
            match: /^user-votes-u(\d+)$/,
            component: () => import('./common/user/user-votes'),
            params: ['id']
          },
          {
            match: /^user-posts-u(\d+)\/page-(\d+)$/,
            component: () => import('./common/user/user-posts'),
            params: ['id', 'page']
          },
          {
            match: /^user-posts-u(\d+)$/,
            component: () => import('./common/user/user-posts'),
            params: ['id']
          },
        ])
      },
      {
        path: '*',
        getComponent() {
          return import('containers/not-found/not-found').then(mod => mod.default);
        },
        status: 404
      }
      */
    ]
  });
}
